/* generated by nativescriptthemebuilder.com */

.action-bar {
	color: #ffffff;
}

.action-bar {
	background-color: #00509b;
}

.page {
	background-color: #ffffff;
}

.tab-view {
	tab-background-color: #ffffff;
}

.list-group, .list-group-item {
	background-color: #ffffff;
}

.page label, .tab-view label {
	color: #000000;
}

.segmented-bar {
	selected-background-color: #00509b;
}

.segmented-bar {
	color: #000000;
}

.segmented-bar {
	background-color: #f8f8f8;
}

.switch[checked=true] {
	color: #ffffff;
}

.switch[checked=true] {
	background-color: #00509b;
}

.activity-indicator {
	color: #00509b;
}

.slider {
	color: #ffffff;
}

.slider {
	background-color: #00509b;
}

.progress {
	color: #00509b;
}

.img-circle {
	border-radius: 20px;
}

.list-group-item-heading {
	font-size: 16px;
}

.list-group-item-text {
	font-size: 14px;
}

.img-rounded {
	border-radius: 0;
}

.body {
	font-size: 14px;
}

.h1 {
	font-size: 36px;
}

.h2 {
	font-size: 30px;
}

.h3 {
	font-size: 24px;
}

.h4 {
	font-size: 18px;
}

.h5 {
	font-size: 14px;
}

.h6 {
	font-size: 12px;
}

.page label.text-primary, .tab-view label.text-primary {
	color: #00509b;
}

.page label.bg-primary, .tab-view label.bg-primary {
	background-color: #00509b;
}

.page label.text-danger, .tab-view label.text-danger {
	color: #be1522;
}

.page label.bg-danger, .tab-view label.bg-danger {
	background-color: #be1522;
}

.page label.text-muted, .tab-view label.text-muted {
	color: #a2a2a2;
}

.btn {
	color: #000000;
}

.btn-primary {
	background-color: #00509b;
}

.btn-primary {
	border-color: #00509b;
}

.btn-primary {
	color: #ffffff;
}

.btn-outline {
	border-color: #00509b;
}

.btn-outline {
	background-color: transparent;
}

.btn-outline {
	border-width: 1px;
}

.btn-outline {
	color: #00509b;
}

.btn[isEnabled=false] {
	background-color: #8184d6;
}

.btn[isEnabled=false] {
	border-color: #8184d6;
}

.btn[isEnabled=false] {
	color: #ffffff;
}

.btn-rounded-sm {
	border-radius: 0;
}

.btn-rounded-lg {
	border-radius: 0;
}

.label {
	font-size: 16px;
}

.input {
	font-size: 14px;
}

.input-rounded {
	border-radius: 0;
}

.tab-view {
	selected-tab-text-color: #ffffff;
}

.tab-view {
	tab-text-color: #ffffff;
}

.tab-view {
	tab-background-color: #00509b;
}

.tab-view {
	android-selected-tab-highlight-color: #00509b;
}

.sidedrawer-header {
	background-color: #00509b;
}

.sidedrawer-header-brand {
	color: #ffffff;
}

.sidedrawer-content {
	background-color: #ffffff;
}

.sidedrawer-list-item {
	color: #000000;
}

.sidedrawer-list-item.active {
	background-color: #00509b;
}

.sidedrawer-list-item.active {
	color: #ffffff;
}

